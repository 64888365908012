import { PageProps } from 'gatsby';
import { ScenarioData } from 'packages/innedit';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import Scenario from '~/components/List/Item/Scenario';
import List from '~/containers/Espace/List';
import params from '~/params/scenario.json';
import TemplateAdmin from '~/templates/admin';
import requireUser, { UserProps } from '~/utils/requireUser';

const AdminScenarios: FC<
  PageProps & UserProps & { pageContext: { tab?: number } }
> = ({ location, pageContext: { tab }, user }) => {
  const { t } = useTranslation();

  return (
    <TemplateAdmin>
      <List
        displayHidden={false}
        itemList={Scenario}
        model={new ScenarioData({ params })}
        search={location.search}
        tabIndex={tab ?? 0}
        title={t('scenarios.title')}
        user={user}
      />
    </TemplateAdmin>
  );
};

export default requireUser(AdminScenarios, 'admin');
