import { PageProps } from 'gatsby';
import React, { FC } from 'react';

import requireUser, { UserProps } from '~/utils/requireUser';

import Scenarios from './index';

const AdminScenariosArchived: FC<PageProps & UserProps> = props => (
  <Scenarios {...props} pageContext={{ tab: 2 }} />
);

export default requireUser(AdminScenariosArchived, 'admin');
