import styled from '@emotion/styled';
import { navigate } from 'gatsby';
import compact from 'lodash/compact';
import { UserData } from 'packages/innedit';
import React, { FC, SyntheticEvent, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { SubmissionError } from 'redux-form';

import { ReducersProps } from '~/reducers';
import phoneUtil, { PNF } from '~/utils/phoneUtil';

import Button from '../../components/Button';
import { DataFieldProps, Form } from '../../packages/formidable';
import { borderRadius, spacing } from '../../styles/theme';

export const LoginEL = styled.div`
  background: #fff;
  padding: ${spacing[6]};
  margin: 0 auto;
  border-radius: ${borderRadius.s};
  border: 1px solid #e5e7eb;
`;

const Login: FC<{ href: string }> = ({ href }) => {
  const formName = 'login';
  const [message, setMessage] = useState<string>();
  const [isSubmissive, setIsSubmissive] = useState<boolean>(true);
  const [kind, setKind] = useState<'email' | 'reset' | 'password' | 'phone'>(
    'phone',
  );
  const [confirmationResult, setConfirmationResult] = useState<any>();
  const [errors, setErrors] = useState<any>();

  const {
    cms: { userIsLogged, user },
  } = useSelector((globalState: ReducersProps<any>) => globalState);

  useEffect(() => {
    UserData.getReCAPTCHA();
  }, []);

  useEffect(() => {
    try {
      UserData.signInWithEmailLink(href);
    } catch (e: unknown) {
      toast.error((e as Error).message);
    }
  }, [href]);

  const handleKindOnClick = (event: SyntheticEvent<HTMLButtonElement>) => {
    event.preventDefault();
    const newKind = event.currentTarget.getAttribute('kind');
    if (newKind) {
      setKind(newKind as any);
      setMessage('');
    }
  };

  useEffect(() => {
    if (userIsLogged && user) {
      if ('admin' === user.type) {
        navigate(`/admin/dashboard`);
      } else {
        const espaceId =
          user.espaceIds && user.espaceIds.length > 0 && user.espaceIds[0];
        if (espaceId) {
          navigate(`/espaces/${espaceId}/dashboard`);
        } else {
          navigate(`/profile`);
        }
      }
    }
  }, [userIsLogged, user?.id]);

  const handleFormSubmit = async ({
    code,
    email,
    password,
    phone,
  }: {
    code?: string;
    email?: string;
    password?: string;
    phone?: string;
  }): Promise<void> => {
    setIsSubmissive(false);
    // let valid = false;
    // try {
    //   valid = await UserData.signInWithAuthCode(email, code);
    // } catch (error) {
    //   throw new SubmissionError({
    //     _error: (error as Error).message,
    //   });
    // }
    //
    // if (!valid) {
    //   throw new SubmissionError({
    //     _error: "Le code n'est pas bon",
    //   });
    // }

    if ('password' === kind && !password) {
      throw new SubmissionError({
        _error: 'Le mot de passe est obligatoire',
      });
    }

    try {
      switch (kind) {
        case 'email': {
          if (email) {
            await UserData.sendSignInLinkToEmail(email, href);
            setMessage(
              "S'il existe un compte avec cette adresse e-mail, un lien de connexion sera envoyé",
            );
          }

          break;
        }

        case 'phone': {
          if (confirmationResult) {
            if (code) {
              confirmationResult.confirm(code).catch((error: any) => {
                setErrors({
                  code: error.message,
                });
              });
            }
          } else if (phone) {
            const phoneNumber = phoneUtil.parseAndKeepRawInput(phone, 'fr');
            const phoneFormatted: string = phoneUtil.format(
              phoneNumber,
              PNF.E164,
            );
            UserData.signInWithPhoneNumber(phoneFormatted)
              .then(setConfirmationResult)
              .catch(error =>
                setErrors({
                  phone: error.message,
                }),
              );
          }

          break;
        }

        case 'reset': {
          if (email) {
            await UserData.sendPasswordResetEmail(email);
            setMessage(
              "S'il existe un compte avec cette adresse e-mail, un lien de réinitialisation sera envoyé",
            );
          }

          break;
        }

        case 'password': {
          if (email) {
            await UserData.signInWithEmailAndPassword(
              email,
              password as string,
            );
          }

          break;
        }

        default:
      }
    } catch (e) {
      throw new SubmissionError({
        _error: (e as Error).message,
      });
    }
    setIsSubmissive(true);
  };

  let submitLabel = 'Se connecter';

  switch (kind) {
    case 'email': {
      submitLabel = 'Envoyer le lien par e-mail';
      break;
    }

    case 'phone': {
      submitLabel = confirmationResult
        ? 'Se connecter'
        : 'Envoyer le code par sms';
      break;
    }

    case 'reset': {
      submitLabel = 'Envoyer un e-mail';
      break;
    }

    default:
  }

  return (
    <>
      <LoginEL className="flex flex-col justify-center space-y-3">
        {confirmationResult && (
          <>
            <p className="mb-3 text-center">
              Vous allez recevoir un code de vérification par sms. <br />
              Veuillez le saisir pour confirmer votre numéro de téléphone.
            </p>
          </>
        )}

        <Form
          bodyProps={{
            className: 'flex flex-col space-y-3 mb-12',
          }}
          datas={compact([
            !confirmationResult &&
              'phone' === kind &&
              ({
                componentType: 'input',
                fieldProps: {
                  autoComplete: 'phone',
                  inputMode: 'tel',
                },
                label: 'Numéro de téléphone',
                name: 'phone',
                required: true,
                type: 'tel',
                wrapperProps: {
                  className: 'relative',
                },
              } as DataFieldProps),
            confirmationResult &&
              ({
                componentType: 'input',
                inputMode: 'numeric',
                labelShow: false,
                name: 'code',
                placeholder: 'Code de vérification',
                required: true,
                type: 'text',
              } as DataFieldProps),
            ['password', 'email', 'reset'].includes(kind) &&
              ({
                componentType: 'input',
                fieldProps: {
                  autoComplete: 'email',
                  inputMode: 'email',
                },
                label: 'Adresse e-mail',
                name: 'email',
                required: true,
                type: 'email',
              } as DataFieldProps),
            'password' === kind &&
              ({
                componentType: 'input',
                fieldProps: {
                  autoComplete: 'current-password',
                  inputMode: 'text',
                },
                label: 'Mot de passe',
                name: 'password',
                required: true,
                type: 'password',
                wrapperProps: {
                  className: 'relative',
                },
              } as DataFieldProps),
            // {
            //   componentType: 'input',
            //   fieldProps: {
            //     autoComplete: 'one-time-code',
            //     inputMode: 'numeric',
            //     pattern: '[0-9]*',
            //   },
            //   label: 'Code authentification',
            //   name: 'code',
            //   required: true,
            //   type: 'text',
            // } as DataFieldProps,
          ])}
          footerProps={{
            className: 'flex flex-col space-y-3 justify-between',
          }}
          isSubmissive={isSubmissive}
          name={formName}
          onSubmit={handleFormSubmit}
          submitProps={{
            className: 'w-full',
            label: submitLabel,
          }}
        >
          {message && (
            <span className="text-center text-info-800">{message}</span>
          )}
          {errors?.phone && (
            <span className="text-center text-danger-800">{errors.phone}</span>
          )}
          {errors?.code && (
            <span className="text-center text-danger-800">{errors.code}</span>
          )}
        </Form>
      </LoginEL>
      <div className="mt-1.5 flex justify-between">
        {isSubmissive && ['password', 'email'].includes(kind) && (
          <Button
            className="text-center"
            datas={{
              kind: 'phone',
            }}
            onClick={handleKindOnClick}
            variant="link"
          >
            Se connecter par téléphone
          </Button>
        )}

        {isSubmissive && ['phone'].includes(kind) && (
          <Button
            className="text-center"
            datas={{
              kind: 'email',
            }}
            onClick={handleKindOnClick}
            variant="link"
          >
            Se connecter par email
          </Button>
        )}
        {['email', 'reset'].includes(kind) && (
          <Button
            className="text-center"
            datas={{
              kind: 'password',
            }}
            onClick={handleKindOnClick}
            variant="link"
          >
            Se connecter par mot de passe
          </Button>
        )}
        {'password' === kind && (
          <Button
            datas={{
              kind: 'reset',
            }}
            onClick={handleKindOnClick}
            variant="link"
          >
            Mot de passe oublié ?
          </Button>
        )}
      </div>
      <div id="recaptcha-container" />
    </>
  );
};

export default Login;
