import IconHome from '~/icons/Home';
import IconStream from '~/icons/Stream';

import IconLayoutGroup from '../../icons/LayoutGroup';
import IconRocket from '../../icons/Rocket';
import IconSeedling from '../../icons/Seedling';
import IconUsers from '../../icons/Users';
import { MenuProps } from './props';

const menu: MenuProps = () => [
  {
    acl: {
      '*': true,
    },
    className: 'flex',
    iconLeft: IconHome,
    name: 'Tableau de bord',
    profil: 'dashboard',
    to: `/admin/dashboard`,
  },
  {
    acl: {
      admin: true,
    },
    iconLeft: IconUsers,
    name: 'Utilisateurs',
    path: '/admin/users/',
    profil: 'admin',
    to: '/admin/users/',
  },
  {
    acl: {
      admin: true,
    },
    iconLeft: IconRocket,
    name: 'Espaces',
    path: '/admin/espaces/',
    profil: 'admin',
    to: '/admin/espaces/',
  },
  {
    acl: {
      admin: true,
    },
    iconLeft: IconStream,
    name: 'Scénarios',
    path: '/admin/scenarios/',
    profil: 'admin',
    to: '/admin/scenarios/',
  },
  {
    acl: {
      admin: true,
    },
    iconLeft: IconLayoutGroup,
    name: 'Schémas',
    path: '/admin/schemas/',
    profil: 'admin',
    to: '/admin/schemas/',
  },
  {
    acl: {
      admin: true,
    },
    iconLeft: IconSeedling,
    name: 'Typesense',
    path: '/admin/typesense/',
    profil: 'admin',
    to: '/admin/typesense/',
  },
];

export default menu;
