import { InteractionType } from '@innedit/innedit-type';
import React, { FC, SyntheticEvent, useState } from 'react';

import Button from '~/components/Button';
import ButtonGroup from '~/components/Button/Group';
import { ItemEL } from '~/datas/Contents/Crud/Item';
import IconArrowDown from '~/icons/ArrowDown';
import IconArrowUp from '~/icons/ArrowUp';
import IconDelete from '~/icons/Delete';
import IconEdit from '~/icons/Edit';

import Modal from './Modal';

export interface DataInteractionsItemProps {
  deleteOnClick?: (event: SyntheticEvent<HTMLButtonElement>) => void;
  downOnClick?: (event: SyntheticEvent<HTMLButtonElement>) => void;
  formName: string;
  index: number;
  name: string;
  params?: {
    [key: string]: any;
  };
  upOnClick?: (event: SyntheticEvent<HTMLButtonElement>) => void;
}
const DataInteractionsItem: FC<DataInteractionsItemProps> = ({
  deleteOnClick,
  downOnClick,
  formName,
  index,
  name,
  params,
  upOnClick,
}) => {
  const [openModal, setOpenModal] = useState<boolean>();

  const parentName = name.slice(0, name.indexOf('['));
  const item: InteractionType | undefined =
    params &&
    params[formName] &&
    params[formName][parentName] &&
    params[formName][parentName][index];

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleEditOnClick = (event: SyntheticEvent<HTMLButtonElement>) => {
    event.preventDefault();
    setOpenModal(true);
  };

  const contents: string[] =
    item?.contents && item.contents.length > 0 ? item.contents : [];

  return (
    <>
      {openModal && (
        <Modal
          closeOnClick={handleCloseModal}
          formName={formName}
          name={name}
          params={params}
          title={name}
        />
      )}
      <ItemEL className="flex min-h-12 flex-col rounded border bg-light-300 p-1">
        {item && (
          <>
            <ul className="mx-4">
              {contents.map(content => (
                <li key={content}>{content}</li>
              ))}
            </ul>
            {item.description && (
              <p className="ml-1 mt-2 border-l pl-2">{item.description}</p>
            )}
            {'evaluation' === item.kind && item.evaluationScale && (
              <span className="ml-1 mt-2 border-l pl-2 text-neutral-500">
                Évaluation sur {item.evaluationScale}
              </span>
            )}
            {'action' === item.kind && item.action && (
              <span className="ml-1 mt-2 border-l pl-2 text-neutral-500">
                {item.action}
              </span>
            )}
          </>
        )}

        <hr className="mt-1" />
        <div className="mt-1 flex items-center justify-between text-neutral-700">
          <div className="ml-2 flex space-x-2 text-xs">
            {item?.id && <span>[{item.id}]</span>}
            {item?.kind && <span>{item.kind}</span>}
          </div>
          <div className="actions items-center justify-center">
            <ButtonGroup>
              <Button
                color="neutral"
                data-index={index}
                iconLeft={IconArrowUp}
                onClick={upOnClick}
                size="xs"
                variant="outline"
              />
              <Button
                color="neutral"
                data-index={index}
                iconLeft={IconArrowDown}
                onClick={downOnClick}
                size="xs"
                variant="outline"
              />
              <Button
                color="neutral"
                iconLeft={IconEdit}
                onClick={handleEditOnClick}
                size="xs"
                variant="outline"
              />
              {deleteOnClick && (
                <Button
                  color="neutral"
                  datas={{
                    'data-index': index,
                  }}
                  iconLeft={IconDelete}
                  onClick={deleteOnClick}
                  size="xs"
                  variant="outline"
                />
              )}
            </ButtonGroup>
          </div>
        </div>
      </ItemEL>
    </>
  );
};

export default DataInteractionsItem;
