import React, { FC } from 'react';
import { FieldArray } from 'redux-form';

import { DataInteractionsProps } from '~/datas/props';

import DataInteractionsRender from './Render';

const DataInteractions: FC<DataInteractionsProps> = ({ name, ...others }) => (
  <FieldArray component={DataInteractionsRender} name={name} props={others} />
);

export default DataInteractions;
