import { DocumentType, ScenarioType } from '@innedit/innedit-type';
import { ScenarioData } from 'packages/innedit';
import React, { FC, useEffect, useState } from 'react';

import { ListItemProps } from '~/containers/Espace/List/props';
// import IconEye from '~/icons/Eye';
// import IconEyeSlash from '~/icons/EyeSlash';
import IconFlag from '~/icons/Flag';
import { colors } from '~/styles/theme';

import ListItem from './index';

const ListItemScenario: FC<ListItemProps<ScenarioType, ScenarioData>> = ({
  docId,
  index,
  model,
  onClick,
}) => {
  const [doc, setDoc] = useState<DocumentType<ScenarioType>>();

  useEffect(() => {
    const unsub = model.watchById(docId, document => {
      setDoc(document);
    });

    return () => {
      if (unsub) {
        unsub();
      }
    };
  }, [docId, model]);

  if (!doc) {
    return null;
  }

  let priorityColor: string;
  switch (doc.priority) {
    case 'urgent': {
      // eslint-disable-next-line prefer-destructuring
      priorityColor = colors.danger[500];
      break;
    }
    case 'high': {
      // eslint-disable-next-line prefer-destructuring
      priorityColor = colors.warning[500];
      break;
    }

    case 'normal': {
      // eslint-disable-next-line prefer-destructuring
      priorityColor = colors.info[400];
      break;
    }

    case 'low': {
      // eslint-disable-next-line prefer-destructuring
      priorityColor = colors.neutral[300];
      break;
    }
    default:
      // eslint-disable-next-line prefer-destructuring
      priorityColor = colors.light[600];
  }

  return (
    <ListItem
      actions={[
        {
          icon: IconFlag,
          iconColor: priorityColor,
          label: 'Changer la priorité',
        },
        // {
        //   icon: doc.hidden ? IconEyeSlash : IconEye,
        //   label: 'Changer la visibilité',
        // },
      ]}
      description={doc.description}
      doc={doc}
      index={index}
      label={doc.label}
      onClick={onClick}
      subLabel={doc.subLabel}
    />
  );
};

export default ListItemScenario;
