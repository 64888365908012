import { DataProps } from 'packages/formidable/components/Data/props';
import React, { FC } from 'react';

import DataAttributeDisplay from '~/datas/Attribute/Display';
import DataCode, { DataCodeProps } from '~/datas/Code';
import DataColor, { DataColorProps } from '~/datas/Color';
import DataColors from '~/datas/Colors';
import DataCommentsCrud from '~/datas/Comments/Crud';
import DataContact from '~/datas/Contact';
import DataContentFields from '~/datas/Content/Fields';
import DataContentsExtra, {
  DataContentsExtraProps,
} from '~/datas/Contents/Extra';
import DataDeliveryRules from '~/datas/Delivery/Rules';
import DataDocumentsDataList, {
  DataDocumentsDataListProps,
} from '~/datas/Documents/Datalist';
import DataEmailSections from '~/datas/Email/Sections';
import DataGoogleSearchConsole from '~/datas/Google/SearchConsole';
import DataImages, { DataImagesProps } from '~/datas/Images';
import DataInquiryMessages from '~/datas/Inquiry/Messages';
import DataInteractions from '~/datas/Interactions';
import DataMediaParent, { DataMediaParentProps } from '~/datas/Media/Parent';
import DataOrdersResume, { DataOrdersResumeProps } from '~/datas/Orders/Resume';
import DataParentProduct, {
  DataParentProductProps,
} from '~/datas/Parent/Product';
import DataPathnames from '~/datas/Pathnames';
import DataProductInput, { DataProductInputProps } from '~/datas/Product/Input';
import DataProductOptions from '~/datas/Product/Options';
import DataProductVariants, {
  DataProductVariantsProps,
} from '~/datas/Product/Variants';
import {
  ChannelsDeploymentProps,
  ChannelsListProps,
  DataAssociationsProps,
  DataAttributeDisplayProps,
  DataAttributesProps,
  DataChannelParentProps,
  DataChannelsCrudProps,
  DataColorSelectProps,
  DataColorsProps,
  DataCommentsCrudProps,
  DataContactProps,
  DataContentFieldsProps,
  DataContentProps,
  DataContentsCrudProps,
  DataDeliveryRulesProps,
  DataEmailSectionsProps,
  DataEspacesListProps,
  DataFeaturesListProps,
  DataFeaturesSelectSubProps,
  DataGoogleSearchConsoleProps,
  DataHyperLinksProps,
  DataInquiryMessagesProps,
  DataInteractionsProps,
  DataMenuProps,
  DataOptionsProps,
  DataOrdersProductsProps,
  DataPaiementItemsProps,
  DataPathnamesProps,
  DataProductOptionsProps,
  DataProduitsDimensionsProps,
  DataSchemasFieldsProps,
  DataSchemasListProps,
  DataSubscriptionProductsProps,
  DataVariantsProps,
  PricesTiersProps,
} from '~/datas/props';
import DataSchemasFieldsAttributes, {
  DataSchemasFieldsAttributesProps,
} from '~/datas/Schemas/Fields/Attributes';
import DataSubscriptionProducts from '~/datas/Subscription/Products';
import DataThoughtsCrud, { DataThoughtsCrudProps } from '~/datas/Thoughts/Crud';
import DataUsersSelect, { DataUsersSelectProps } from '~/datas/Users/Select';
import DataVariants from '~/datas/Variants';

import DataAnalytics, { DataAnalyticsProps } from './Analytics';
import DataArticleParent, { DataArticleParentProps } from './Article/Parent';
import DataAssociations from './Associations';
import DataAttribute, { DataAttributeProps } from './Attribute';
import DataAttributes from './Attributes';
import DataButtonStyles, { DataButtonStylesProps } from './Button/Styles';
import DataChannelParent from './Channel/Parent';
import DataChannelsCrud from './Channels/Crud';
import ChannelsDeployment from './Channels/Deployment';
import ChannelsList from './Channels/List';
import DataCollectionsSelect, {
  DataCollectionsSelectProps,
} from './Collections/Select';
import DataColorSelect from './Color/Select';
import DataContent from './Content';
import DataContentsCrud from './Contents/Crud';
import DataDeliveryEstimation, {
  DataDeliveryEstimationProps,
} from './Delivery/Estimation';
import DataDocumentsSelect, {
  DataDocumentsSelectProps,
} from './Documents/Select';
import DataEspaces, { DataEspacesProps } from './Espace';
import DataEspacesList from './Espaces/List';
import DataEspacesSelect, { DataEspacesSelectProps } from './Espaces/Select';
import DataFeaturesList from './Features/List';
import FeaturesMultiSelect, {
  FeaturesMultiSelectProps,
} from './Features/MultiSelect';
import DataFeaturesSelectSub from './Features/SelectSub';
import DataField from './Field';
import DataFieldCountry, { DataFieldCountryProps } from './Field/Country';
import DataFieldPhone, { DataFieldPhoneProps } from './Field/Phone';
import DataGoogleResults, { DataGoogleResultsProps } from './Google/Results';
import DataHyperlinks from './Hyperlinks';
import DataLanguagesTraductions, {
  DataLanguagesTraductionsProps,
} from './Languages/Traductions';
import DataMedia, { DataMediaProps } from './Media';
import DataMedias, { DataMediasProps } from './Medias';
import DataMenu from './Menu';
import DataOptions from './Options';
import DataOrdersProducts from './Orders/Products';
import DataPageParent, { DataPageParentProps } from './Page/Parent';
import DataPagesList, { DataPagesListProps } from './Pages/List';
import DataPagesSelect, { DataPagesSelectProps } from './Pages/Select';
import DataPaiementItems from './Paiement/Items';
import DataPaiementManager, {
  DataPaiementManagerProps,
} from './Paiement/Manager';
import DataPaiementsMode, { DataPaiementsModeProps } from './Paiements/Mode';
import DataParentInfo, { DataParentInfoProps } from './Parent/Info';
import DataPrice, { DataPriceProps } from './Price';
import DataPrices, { DataPricesProps } from './Prices';
import DataPricesCrud, { DataPricesCrudProps } from './Prices/Crud';
import DataPricesSelect, { DataPricesSelectProps } from './Prices/Select';
import PricesTiers from './Prices/Tiers';
import DataProduct, { DataProductProps } from './Product';
import DataProduitParent, { DataProduitParentProps } from './Product/Parent';
import DataProduitProgram, { DataProduitProgramProps } from './Product/Program';
import DataProductSlots, { DataProductSlotsProps } from './Product/Slots';
import DataProduitsDimensions from './Produits/Dimensions';
import DataProduitsInventaire, {
  DataProduitsInventaireProps,
} from './Produits/Inventaire';
import DataProfessionalsKYC, {
  DataProfessionalsKYCProps,
} from './Professionals/KYC';
import DataProfessionalsMultiSelect, {
  DataProfessionalsMultiSelectProps,
} from './Professionals/MultiSelect';
import SchemasDatas, { SchemasDatasProps } from './Schemas/Datas';
import DataSchemaDatasLabel, {
  DataSchemasDatasLabelProps,
} from './Schemas/Datas/Label';
import DataSchemasFields from './Schemas/Fields';
import DataSchemasFieldsSelect, {
  DataSchemasFieldsSelectProps,
} from './Schemas/Fields/Select';
import DataSchemasList from './Schemas/List';
import DataSchemasSelect, { DataSchemasSelectProps } from './Schemas/Select';
import TarificationsSelect, {
  TarificationsSelectProps,
} from './Tarifications/Select';
import DataUtilityCounter, { DataUtilityCounterProps } from './Utility/Counter';
import DataUtilityQRCode, { DataUtilityQRCodeProps } from './Utility/QRCode';

const Data: FC<unknown> = p => {
  const { componentType, ...props } = p as DataProps;
  const { params } = props;
  let componentProps;

  let data: Record<string, any> = {};

  if (params && props.formName && params[props.formName]) {
    data = params[props.formName];
  }

  switch (componentType) {
    case 'analytics': {
      componentProps = props as Partial<DataAnalyticsProps>;

      if (!componentProps.params?.docId) {
        return (
          <div>
            {componentType}: erreur de paramètre : docId est obligatoire
          </div>
        );
      }

      return (
        <DataAnalytics
          {...componentProps}
          channelId={componentProps.params.docId}
        />
      );
    }

    case 'article/parent': {
      componentProps = props as Partial<DataArticleParentProps>;

      if (!componentProps.formName) {
        return (
          <div>
            {componentType}: erreur de paramètre : formName est obligatoire
          </div>
        );
      }

      if (!params?.espaceId) {
        return (
          <div>
            {componentType}: erreur de paramètre : espaceId est obligatoire
          </div>
        );
      }

      if (!params?.articleId) {
        return (
          <div>
            {componentType}: erreur de paramètre : articleId est obligatoire
          </div>
        );
      }

      return (
        <DataArticleParent
          {...componentProps}
          articleId={params.articleId}
          espaceId={params.espaceId}
          formName={componentProps.formName}
        />
      );
    }

    case 'associations': {
      componentProps = props as Partial<DataAssociationsProps>;

      if (!componentProps.formName) {
        return (
          <div>
            {componentType}: erreur de paramètre : formName est obligatoire
          </div>
        );
      }

      if (!componentProps.name) {
        return (
          <div>{componentType}: erreur de paramètre : name est obligatoire</div>
        );
      }

      if (!params?.espaceId) {
        return (
          <div>
            {componentType}: erreur de paramètre : espaceId est obligatoire
          </div>
        );
      }

      return (
        <DataAssociations
          {...componentProps}
          espaceId={params.espaceId}
          formName={componentProps.formName}
          name={componentProps.name}
        />
      );
    }

    case 'attribute': {
      componentProps = props as DataAttributeProps;

      if (!componentProps.attributeType) {
        return (
          <div>
            {componentType}: erreur de paramètre : attributeType est obligatoire
          </div>
        );
      }

      return (
        <DataAttribute
          {...componentProps}
          attributeType={componentProps.attributeType}
        />
      );
    }

    case 'attribute/display': {
      componentProps = props as DataAttributeDisplayProps;

      if (!componentProps.attributeType) {
        return (
          <div>
            {componentType}: erreur de paramètre : attributeType est obligatoire
          </div>
        );
      }

      if (!componentProps.formName) {
        return (
          <div>
            {componentType}: erreur de paramètre : formName est obligatoire
          </div>
        );
      }

      return (
        <DataAttributeDisplay
          {...componentProps}
          attributeType={componentProps.attributeType}
          formName={componentProps.formName}
        />
      );
    }

    case 'attributes': {
      componentProps = props as DataAttributesProps;
      if (!componentProps.formName) {
        return (
          <div>
            {componentType}: erreur de paramètre : formName est obligatoire
          </div>
        );
      }

      if (!componentProps.name) {
        return (
          <div>{componentType}: erreur de paramètre : name est obligatoire</div>
        );
      }

      return (
        <DataAttributes
          {...componentProps}
          formName={componentProps.formName}
          name={componentProps.name}
        />
      );
    }
    case 'espace': {
      componentProps = props as DataEspacesProps;
      if (!componentProps.type) {
        return (
          <div>
            {componentType} : erreur de paramètre : type est obligatoire
          </div>
        );
      }

      return <DataEspaces {...componentProps} espaceId={params?.espaceId} />;
    }

    case 'button/styles': {
      componentProps = props as DataButtonStylesProps;

      if (!componentProps.formName) {
        return (
          <div>
            {componentType} : erreur de paramètre : formName est obligatoire
          </div>
        );
      }

      if (!componentProps.name) {
        return (
          <div>
            {componentType} : erreur de paramètre : name est obligatoire
          </div>
        );
      }

      return (
        <DataButtonStyles
          {...componentProps}
          formName={componentProps.formName}
          name={componentProps.name}
        />
      );
    }

    case 'channel/parent': {
      componentProps = props as Partial<DataChannelParentProps>;
      if (!params?.espaceId) {
        return (
          <div>
            {componentType}: erreur de paramètre : espaceId est obligatoire
          </div>
        );
      }

      if (!params?.channelId) {
        return (
          <div>
            {componentType}: erreur de paramètre : channelId est obligatoire
          </div>
        );
      }

      return (
        <DataChannelParent
          {...componentProps}
          channelId={params.channelId}
          espaceId={params.espaceId}
        />
      );
    }

    case 'channels/crud': {
      componentProps = props as Partial<DataChannelsCrudProps>;

      return <DataChannelsCrud {...componentProps} />;
    }

    case 'channels/deployment': {
      componentProps = props as Partial<ChannelsDeploymentProps>;

      if (!componentProps.formName) {
        return (
          <div>
            {componentType}: erreur de paramètre : formName est obligatoire
          </div>
        );
      }

      return (
        <ChannelsDeployment
          {...componentProps}
          formName={componentProps.formName}
        />
      );
    }

    case 'channels/list': {
      componentProps = props as Partial<ChannelsListProps>;

      if (!componentProps.formName) {
        return (
          <div>
            {componentType}: erreur de paramètre : formName est obligatoire
          </div>
        );
      }

      if (!componentProps.name) {
        return (
          <div>{componentType}: erreur de paramètre : name est obligatoire</div>
        );
      }

      return (
        <ChannelsList
          {...componentProps}
          formName={componentProps.formName}
          name={componentProps.name}
        />
      );
    }

    case 'code': {
      componentProps = props as Partial<DataCodeProps>;
      if (!componentProps.name) {
        return (
          <div>
            {componentType} : erreur de paramètre : name est obligatoire
          </div>
        );
      }

      if (!componentProps.formName) {
        return (
          <div>
            {componentType} : erreur de paramètre : formName est obligatoire
          </div>
        );
      }

      return (
        <DataCode
          {...componentProps}
          formName={componentProps.formName}
          name={componentProps.name}
        />
      );
    }

    case 'collections/select': {
      componentProps = props as Partial<DataCollectionsSelectProps>;
      if (!componentProps.name) {
        return (
          <div>
            {componentType} : erreur de paramètre : name est obligatoire
          </div>
        );
      }

      return (
        <DataCollectionsSelect {...componentProps} name={componentProps.name} />
      );
    }

    case 'color': {
      componentProps = props as Partial<DataColorProps>;
      if (!componentProps.formName) {
        return (
          <div>
            {componentType}: erreur de paramètre : formName est obligatoire
          </div>
        );
      }

      if (!componentProps.name) {
        return (
          <div>
            {componentType} : erreur de paramètre : name est obligatoire
          </div>
        );
      }

      return (
        <DataColor
          {...componentProps}
          formName={componentProps.formName}
          name={componentProps.name}
        />
      );
    }

    case 'colors': {
      componentProps = props as Partial<DataColorsProps>;

      if (!componentProps.formName) {
        return (
          <div>
            {componentType}: erreur de paramètre : formName est obligatoire
          </div>
        );
      }

      if (!componentProps.label) {
        return (
          <div>
            {componentType}: erreur de paramètre : label est obligatoire
          </div>
        );
      }

      if (!componentProps.name) {
        return (
          <div>
            {componentType} : erreur de paramètre : name est obligatoire
          </div>
        );
      }
      if (!params?.espaceId) {
        return (
          <div>
            {componentType} : erreur de paramètre : espaceId est obligatoire
          </div>
        );
      }

      return (
        <DataColors
          {...componentProps}
          espaceId={params.espaceId}
          formName={componentProps.formName}
          label={componentProps.label}
          name={componentProps.name}
        />
      );
    }

    case 'orders/produits': {
      componentProps = props as Partial<DataOrdersProductsProps>;

      if (!params?.espaceId) {
        return (
          <div>
            {componentType}: erreur de paramètre : espaceId est obligatoire
          </div>
        );
      }

      if (!componentProps.formName) {
        return (
          <div>
            {componentType}: erreur de paramètre : formName est obligatoire
          </div>
        );
      }

      if (!componentProps.name) {
        return (
          <div>{componentType}: erreur de paramètre : name est obligatoire</div>
        );
      }

      return (
        <DataOrdersProducts
          {...componentProps}
          espaceId={params.espaceId}
          formName={componentProps.formName}
          name={componentProps.name}
        />
      );
    }

    case 'orders/resume': {
      componentProps = props as Partial<DataOrdersResumeProps>;

      if (!params?.espaceId) {
        return (
          <div>
            {componentType}: erreur de paramètre : espaceId est obligatoire
          </div>
        );
      }

      if (!componentProps.formName) {
        return (
          <div>
            {componentType}: erreur de paramètre : formName est obligatoire
          </div>
        );
      }

      return (
        <DataOrdersResume
          {...componentProps}
          espaceId={params.espaceId}
          formName={componentProps.formName}
        />
      );
    }

    case 'contact': {
      componentProps = props as Partial<DataContactProps>;

      if (!componentProps.formName) {
        return (
          <div>
            {componentType}: erreur de paramètre : formName est obligatoire
          </div>
        );
      }
      if (!params?.espaceId) {
        return (
          <div>
            {componentType}: erreur de paramètre : espaceId est obligatoire
          </div>
        );
      }

      return (
        <DataContact
          {...componentProps}
          espaceId={params.espaceId}
          formName={componentProps.formName}
        />
      );
    }

    case 'comments/crud': {
      componentProps = props as Partial<DataCommentsCrudProps>;

      if (!componentProps.formName) {
        return (
          <div>
            {componentType}: erreur de paramètre : formName est obligatoire
          </div>
        );
      }

      if (!params?.parentCollectionName && !params?.collectionName) {
        return (
          <div>
            {componentType}: erreur de paramètre : parentCollectionName ou
            collectionName est obligatoire
          </div>
        );
      }

      if (!params?.parentId && !params?.docId) {
        return (
          <div>
            {componentType}: erreur de paramètre : parentId ou docId est
            obligatoire
          </div>
        );
      }

      return (
        <DataCommentsCrud
          {...componentProps}
          espaceId={params.espaceId}
          formName={componentProps.formName}
          parentCollectionName={
            params.parentCollectionName ?? params.collectionName
          }
          parentId={params.parentId ?? params.docId}
        />
      );
    }

    case 'color/select': {
      componentProps = props as Partial<DataColorSelectProps>;

      if (!componentProps.name) {
        return (
          <div>{componentType}: erreur de paramètre : name est obligatoire</div>
        );
      }

      if (!params?.espaceId) {
        return (
          <div>
            {componentType}: erreur de paramètre : espaceId est obligatoire
          </div>
        );
      }

      return (
        <DataColorSelect
          {...componentProps}
          channelId={params.channelId}
          espaceId={params.espaceId}
          name={componentProps.name}
        />
      );
    }

    case 'content': {
      componentProps = props as Partial<DataContentProps>;

      return <DataContent {...componentProps} />;
    }

    case 'content/fields': {
      componentProps = props as Partial<DataContentFieldsProps>;

      if (!componentProps.formName) {
        return (
          <div>
            {componentType}: erreur de paramètre : formName est obligatoire
          </div>
        );
      }

      if (!componentProps.name) {
        return (
          <div>{componentType}: erreur de paramètre : name est obligatoire</div>
        );
      }

      if (!params?.espaceId) {
        return (
          <div>
            {componentType}: erreur de paramètre : espaceId est obligatoire
          </div>
        );
      }

      return (
        <DataContentFields
          {...componentProps}
          espaceId={params.espaceId}
          formName={componentProps.formName}
          name={componentProps.name}
        />
      );
    }

    case 'contents/crud': {
      componentProps = props as Partial<DataContentsCrudProps>;

      if (!componentProps.formName) {
        return (
          <div>
            {componentType}: erreur de paramètre : formName est obligatoire
          </div>
        );
      }

      if (!params?.parentCollectionName && !params?.collectionName) {
        return (
          <div>
            {componentType}: erreur de paramètre : parentCollectionName ou
            collectionName est obligatoire
          </div>
        );
      }

      if (
        !componentProps.name &&
        (!params[componentProps.formName] ||
          !params[componentProps.formName].parentField)
      ) {
        return (
          <div>{componentType}: erreur de paramètre : name est obligatoire</div>
        );
      }

      if (!params?.parentId && !params?.docId) {
        return (
          <div>
            {componentType}: erreur de paramètre : parentId ou docId est
            obligatoire
          </div>
        );
      }

      return (
        <DataContentsCrud
          {...componentProps}
          espaceId={params.espaceId}
          formName={componentProps.formName}
          name={componentProps.name ?? params.parentField}
          parentCollectionName={
            params.parentCollectionName ?? params.collectionName
          }
          parentId={params.parentId ?? params.docId}
        />
      );
    }

    case 'contents/extra': {
      componentProps = props as Partial<DataContentsExtraProps>;

      if (!componentProps.formName) {
        return (
          <div>
            {componentType}: erreur de paramètre : formName est obligatoire
          </div>
        );
      }

      if (!componentProps.name) {
        return (
          <div>{componentType}: erreur de paramètre : name est obligatoire</div>
        );
      }

      if (!params?.collectionName) {
        return (
          <div>
            {componentType}: erreur de paramètre : collectionName est
            obligatoire
          </div>
        );
      }

      if (!params.docId) {
        return (
          <div>
            {componentType}: erreur de paramètre : parentId ou docId est
            obligatoire
          </div>
        );
      }

      return (
        <DataContentsExtra
          {...componentProps}
          espaceId={params.espaceId}
          formName={componentProps.formName}
          name={componentProps.name}
          parentCollectionName={params.collectionName}
          parentId={params.docId}
        />
      );
    }

    case 'delivery/estimation':
      componentProps = props as Partial<DataDeliveryEstimationProps>;
      if (!params?.espaceId) {
        return (
          <div>
            {componentType}: erreur de paramètre : espaceId est obligatoire
          </div>
        );
      }

      if (!componentProps.formName) {
        return (
          <div>
            {componentType}: erreur de paramètre : formName est obligatoire
          </div>
        );
      }

      return (
        <DataDeliveryEstimation
          {...componentProps}
          docId={params.docId}
          espaceId={params.espaceId}
          formName={componentProps.formName}
        />
      );

    case 'delivery/rules': {
      componentProps = props as Partial<DataDeliveryRulesProps>;

      if (!componentProps.name) {
        return (
          <div>{componentType}: erreur de paramètre : name est obligatoire</div>
        );
      }

      if (!componentProps.formName) {
        return (
          <div>
            {componentType}: erreur de paramètre : formName est obligatoire
          </div>
        );
      }

      return (
        <DataDeliveryRules
          {...componentProps}
          formName={componentProps.formName}
          name={componentProps.name}
        />
      );
    }

    case 'documents/datalist': {
      componentProps = props as Partial<DataDocumentsDataListProps>;

      if (!componentProps.name) {
        return (
          <div>{componentType}: erreur de paramètre : name est obligatoire</div>
        );
      }

      if (!componentProps.collectionId) {
        return (
          <div>
            {componentType}: erreur de paramètre : collectionId est obligatoire
          </div>
        );
      }

      if (!params?.espaceId) {
        return (
          <div>
            {componentType}: erreur de paramètre : espaceId est obligatoire
          </div>
        );
      }

      return (
        <DataDocumentsDataList
          {...componentProps}
          collectionId={componentProps.collectionId}
          espaceId={params.espaceId}
          name={componentProps.name}
        />
      );
    }

    case 'documents/select': {
      componentProps = props as Partial<DataDocumentsSelectProps>;

      if (!componentProps.name) {
        return (
          <div>{componentType}: erreur de paramètre : name est obligatoire</div>
        );
      }

      if (!componentProps.collectionId) {
        return (
          <div>
            {componentType}: erreur de paramètre : collectionId est obligatoire
          </div>
        );
      }

      if (!params?.espaceId && !data.espaceId) {
        return (
          <div>
            {componentType}: erreur de paramètre : espaceId est obligatoire
          </div>
        );
      }

      return (
        <DataDocumentsSelect
          {...componentProps}
          collectionId={componentProps.collectionId}
          espaceId={data.espaceId ?? params?.espaceId}
          name={componentProps.name}
        />
      );
    }

    case 'email/sections': {
      componentProps = props as Partial<DataEmailSectionsProps>;
      if (!componentProps.name) {
        return (
          <div>{componentType}: erreur de paramètre : name est obligatoire</div>
        );
      }

      if (!componentProps.formName) {
        return (
          <div>
            {componentType}: erreur de paramètre : formName est obligatoire
          </div>
        );
      }

      if (!params?.espaceId) {
        return (
          <div>
            {componentType}: erreur de paramètre : espaceId est obligatoire
          </div>
        );
      }

      return (
        <DataEmailSections
          {...componentProps}
          espaceId={params.espaceId}
          formName={componentProps.formName}
          name={componentProps.name}
        />
      );
    }

    case 'espaces/select': {
      componentProps = props as Partial<DataEspacesSelectProps>;
      if (!componentProps.name) {
        return (
          <div>{componentType}: erreur de paramètre : name est obligatoire</div>
        );
      }

      return (
        <DataEspacesSelect {...componentProps} name={componentProps.name} />
      );
    }

    case 'espaces/list': {
      componentProps = props as Partial<DataEspacesListProps>;

      if (!componentProps.name) {
        return (
          <div>{componentType}: erreur de paramètre : name est obligatoire</div>
        );
      }

      if (!componentProps.formName) {
        return (
          <div>
            {componentType}: erreur de paramètre : formName est obligatoire
          </div>
        );
      }

      if (!componentProps.title) {
        return (
          <div>
            {componentType}: erreur de paramètre : title est obligatoire
          </div>
        );
      }

      return (
        <DataEspacesList
          {...componentProps}
          formName={componentProps.formName}
          name={componentProps.name}
          title={componentProps.title}
        />
      );
    }

    case 'features/list': {
      componentProps = props as Partial<DataFeaturesListProps>;

      if (!componentProps.collectionName) {
        return (
          <div>
            {componentType}: erreur de paramètre : collectionName est
            obligatoire
          </div>
        );
      }

      return (
        <DataFeaturesList
          {...componentProps}
          collectionName={componentProps.collectionName}
          espaceId={params?.espaceId}
        />
      );
    }

    case 'features/multiselect': {
      componentProps = props as Partial<FeaturesMultiSelectProps>;

      if (!params?.espaceId && !data.espaceId) {
        return (
          <div>
            {componentType} : erreur de paramètre : espaceId est obligatoire
          </div>
        );
      }
      if (!componentProps.name) {
        return (
          <div>
            {componentType} : erreur de paramètre : name est obligatoire
          </div>
        );
      }

      return (
        <FeaturesMultiSelect
          {...props}
          espaceId={data.espaceId ?? params?.espaceId}
          name={componentProps.name}
        />
      );
    }

    case 'features/select-sub': {
      componentProps = props as Partial<DataFeaturesSelectSubProps>;
      if (!params?.espaceId) {
        return (
          <div>
            {componentType}: erreur de paramètre : espaceId est obligatoire
          </div>
        );
      }
      if (!componentProps.collectionName) {
        return (
          <div>
            {componentType}: erreur de paramètre : collectionName est
            obligatoire
          </div>
        );
      }

      if (!componentProps.formName) {
        return (
          <div>
            {componentType}: erreur de paramètre : formName est obligatoire
          </div>
        );
      }

      if (!componentProps.name) {
        return (
          <div>{componentType}: erreur de paramètre : name est obligatoire</div>
        );
      }

      return (
        <DataFeaturesSelectSub
          {...componentProps}
          collectionName={componentProps.collectionName}
          espaceId={params.espaceId}
          formName={componentProps.formName}
          name={componentProps.name}
        />
      );
    }

    case 'field': {
      componentProps = props as any;
      if (!componentProps.field) {
        return (
          <div>
            {componentType}: erreur de paramètre : field est obligatoire
          </div>
        );
      }

      return <DataField {...componentProps} name={componentProps.field} />;
    }

    case 'field/country': {
      componentProps = props as Partial<DataFieldCountryProps>;

      if (!componentProps.name) {
        return (
          <div>{componentType}: erreur de paramètre : name est obligatoire</div>
        );
      }

      return (
        <DataFieldCountry {...componentProps} name={componentProps.name} />
      );
    }

    case 'field/phone': {
      componentProps = props as Partial<DataFieldPhoneProps>;
      if (!componentProps.formName) {
        return (
          <div>
            {componentType}: erreur de paramètre : formName est obligatoire
          </div>
        );
      }

      return (
        <DataFieldPhone
          {...componentProps}
          formName={componentProps.formName}
        />
      );
    }

    case 'images': {
      componentProps = props as Partial<DataImagesProps>;
      if (!componentProps.formName) {
        return (
          <div>
            {componentType}: erreur de paramètre : formName est obligatoire
          </div>
        );
      }

      return (
        <DataImages {...componentProps} formName={componentProps.formName} />
      );
    }

    case 'inquiry/messages': {
      componentProps = props as Partial<DataInquiryMessagesProps>;
      if (!componentProps.formName) {
        return (
          <div>
            {componentType}: erreur de paramètre : formName est obligatoire
          </div>
        );
      }

      if (!params?.espaceId) {
        return (
          <div>
            {componentType}: erreur de paramètre : espaceId est obligatoire
          </div>
        );
      }

      return (
        <DataInquiryMessages
          {...componentProps}
          espaceId={params.espaceId}
          formName={componentProps.formName}
        />
      );
    }

    case 'languages/traductions': {
      componentProps = props as Partial<DataLanguagesTraductionsProps>;
      if (!componentProps.datas) {
        return (
          <div>
            {componentType}: erreur de paramètre : datas est obligatoire
          </div>
        );
      }

      if (!params?.espaceId && !data.espaceId) {
        return (
          <div>
            {componentType}: erreur de paramètre : espaceId est obligatoire
          </div>
        );
      }

      if (!componentProps.name) {
        return (
          <div>{componentType}: erreur de paramètre : name est obligatoire</div>
        );
      }
      if (!componentProps.formName) {
        return (
          <div>
            {componentType}: erreur de paramètre : formName est obligatoire
          </div>
        );
      }

      return (
        <DataLanguagesTraductions
          {...componentProps}
          datas={componentProps.datas}
          espaceId={data.espaceId ?? params?.espaceId}
          formName={componentProps.formName}
          name={componentProps.name}
        />
      );
    }

    case 'media': {
      componentProps = props as Partial<DataMediaProps>;

      if (!params?.espaceId && !data.espaceId) {
        return (
          <div>
            {componentType}: erreur de paramètre : espaceId est obligatoire
          </div>
        );
      }

      if (!componentProps.formName) {
        return (
          <div>
            {componentType}: erreur de paramètre : formName est obligatoire
          </div>
        );
      }

      return (
        <DataMedia
          espaceId={data.espaceId ?? params?.espaceId}
          {...componentProps}
          formName={componentProps.formName}
        />
      );
    }

    case 'media/parent': {
      componentProps = props as Partial<DataMediaParentProps>;

      if (!params?.espaceId && !data.espaceId) {
        return (
          <div>
            {componentType}: erreur de paramètre : espaceId est obligatoire
          </div>
        );
      }

      if (!componentProps.formName) {
        return (
          <div>
            {componentType} : erreur de paramètre : formName est obligatoire
          </div>
        );
      }

      // if (!params.parentCollectionName) {
      //   return (
      //     <div>
      //       {componentType}: erreur de paramètre : parentCollectionName est
      //       obligatoire
      //     </div>
      //   );
      // }
      // if (!params.parentId) {
      //   return (
      //     <div>
      //       {componentType}: erreur de paramètre : parentId est obligatoire
      //     </div>
      //   );
      // }

      return (
        <DataMediaParent
          {...componentProps}
          espaceId={data.espaceId ?? params?.espaceId}
          formName={componentProps.formName}
        />
      );
    }
    case 'medias': {
      componentProps = props as Partial<DataMediasProps>;

      if (!componentProps.name) {
        return (
          <div>{componentType}: erreur de paramètre : name est obligatoire</div>
        );
      }

      if (!params?.espaceId && !data.espaceId) {
        return (
          <div>
            {componentType}: erreur de paramètre : espaceId est obligatoire
          </div>
        );
      }

      if (!params?.parentCollectionName && !params?.collectionName) {
        return (
          <div>
            {componentType}: erreur de paramètre : parentCollectionName ou
            collectionName est obligatoire
          </div>
        );
      }

      if (!params.docId) {
        return (
          <div>
            {componentType}: erreur de paramètre : docId est obligatoire
          </div>
        );
      }

      return (
        <DataMedias
          {...componentProps}
          espaceId={data.espaceId ?? params.espaceId}
          name={componentProps.name}
          parentCollectionName={params.collectionName}
          parentId={params.docId}
        />
      );
    }

    case 'menu': {
      componentProps = props as Partial<DataMenuProps>;

      if (!componentProps.name) {
        return (
          <div>
            {componentType} : erreur de paramètre : name est obligatoire
          </div>
        );
      }

      return <DataMenu {...componentProps} name={componentProps.name} />;
    }

    case 'options': {
      componentProps = props as Partial<DataOptionsProps>;

      if (!componentProps.name) {
        return (
          <div>
            {componentType} : erreur de paramètre : name est obligatoire
          </div>
        );
      }
      if (!componentProps.formName) {
        return (
          <div>
            {componentType} : erreur de paramètre : formName est obligatoire
          </div>
        );
      }

      return (
        <DataOptions
          {...componentProps}
          formName={componentProps.formName}
          name={componentProps.name}
        />
      );
    }

    case 'payment/manager': {
      componentProps = props as Partial<DataPaiementManagerProps>;

      if (!componentProps.formName) {
        return (
          <div>
            {componentType}: erreur de paramètre : formName est obligatoire
          </div>
        );
      }

      if (!params?.espaceId) {
        return (
          <div>
            {componentType}: erreur de paramètre : espaceId est obligatoire
          </div>
        );
      }

      if (!params.docId) {
        return (
          <div>
            {componentType}: erreur de paramètre : docId est obligatoire
          </div>
        );
      }

      return (
        <DataPaiementManager
          {...componentProps}
          docId={params.docId}
          espaceId={params.espaceId}
          formName={componentProps.formName}
        />
      );
    }

    case 'paiement/items': {
      componentProps = props as Partial<DataPaiementItemsProps>;

      if (!componentProps.formName) {
        return (
          <div>
            {componentType}: erreur de paramètre : formName est obligatoire
          </div>
        );
      }

      if (!componentProps.name) {
        return (
          <div>{componentType}: erreur de paramètre : name est obligatoire</div>
        );
      }

      if (!params?.espaceId) {
        return (
          <div>
            {componentType}: erreur de paramètre : espaceId est obligatoire
          </div>
        );
      }

      return (
        <DataPaiementItems
          {...componentProps}
          espaceId={params.espaceId}
          formName={componentProps.formName}
          name={componentProps.name}
        />
      );
    }

    case 'page/parent': {
      componentProps = props as Partial<DataPageParentProps>;
      if (!params?.espaceId) {
        return (
          <div>
            {componentType}: erreur de paramètre : espaceId est obligatoire
          </div>
        );
      }

      if (!params?.channelId) {
        return (
          <div>
            {componentType}: erreur de paramètre : channelId est obligatoire
          </div>
        );
      }

      if (!params?.parentId) {
        return (
          <div>
            {componentType}: erreur de paramètre : parentId est obligatoire
          </div>
        );
      }

      return (
        <DataPageParent
          {...componentProps}
          channelId={params.channelId}
          espaceId={params.espaceId}
          parentId={params.parentId}
        />
      );
    }

    case 'pages/list': {
      componentProps = props as Partial<DataPagesListProps>;

      if (!params?.channelId) {
        return (
          <div>
            {componentType}: erreur de paramètre : channelId est obligatoire
          </div>
        );
      }

      if (!params?.espaceId) {
        return (
          <div>
            {componentType}: erreur de paramètre : espaceId est obligatoire
          </div>
        );
      }

      return (
        <DataPagesList
          {...componentProps}
          channelId={params.channelId}
          espaceId={params.espaceId}
        />
      );
    }

    case 'pages/select': {
      componentProps = props as Partial<DataPagesSelectProps>;

      if (!params?.channelId) {
        return (
          <div>
            {componentType}: erreur de paramètre : channelId est obligatoire
          </div>
        );
      }

      if (!componentProps.formName) {
        return (
          <div>
            {componentType}: erreur de paramètre : formName est obligatoire
          </div>
        );
      }

      if (!componentProps.name) {
        return (
          <div>{componentType}: erreur de paramètre : name est obligatoire</div>
        );
      }

      return (
        <DataPagesSelect
          {...componentProps}
          channelId={params.channelId}
          espaceId={params?.espaceId}
          formName={componentProps.formName}
          id={params?.docId}
          name={componentProps.name}
        />
      );
    }

    case 'pathnames': {
      componentProps = props as Partial<DataPathnamesProps>;

      if (!componentProps.formName) {
        return (
          <div>
            {componentType}: erreur de paramètre : formName est obligatoire
          </div>
        );
      }

      if (!componentProps.name) {
        return (
          <div>{componentType}: erreur de paramètre : name est obligatoire</div>
        );
      }

      return (
        <DataPathnames
          {...componentProps}
          formName={componentProps.formName}
          name={componentProps.name}
        />
      );
    }

    case 'prices/crud': {
      componentProps = props as Partial<DataPricesCrudProps>;

      if (!params?.espaceId) {
        return (
          <div>
            {componentType}: erreur de paramètre : espaceId est obligatoire
          </div>
        );
      }

      if (!params.collectionName) {
        return (
          <div>
            {componentType}: erreur de paramètre : params.collectionName est
            obligatoire
          </div>
        );
      }

      if (!componentProps.formName) {
        return (
          <div>
            {componentType}: erreur de paramètre : formName est obligatoire
          </div>
        );
      }

      if (!componentProps.editPathname) {
        return (
          <div>
            {componentType}: erreur de paramètre : editPathname est obligatoire
          </div>
        );
      }

      return (
        <DataPricesCrud
          {...componentProps}
          docCollectionName={params.collectionName}
          docId={params.docId}
          editPathname={componentProps.editPathname}
          espaceId={params.espaceId}
          formName={componentProps.formName}
        />
      );
    }

    case 'prices/select': {
      componentProps = props as Partial<DataPricesSelectProps>;

      if (!componentProps.formName) {
        return (
          <div>
            {componentType}: erreur de paramètre : formName est obligatoire
          </div>
        );
      }

      if (!componentProps.name) {
        return (
          <div>{componentType}: erreur de paramètre : name est obligatoire</div>
        );
      }

      if (!params?.espaceId) {
        return (
          <div>
            {componentType}: erreur de paramètre : espaceId est obligatoire
          </div>
        );
      }

      if (!params.collectionName) {
        return (
          <div>
            {componentType}: erreur de paramètre : params.collectionName est
            obligatoire
          </div>
        );
      }

      return (
        <DataPricesSelect
          {...componentProps}
          docCollectionName={params.collectionName}
          docId={params.docId}
          espaceId={params.espaceId}
          formName={componentProps.formName}
          name={componentProps.name}
        />
      );
    }

    case 'paiements/mode': {
      componentProps = props as Partial<DataPaiementsModeProps>;

      if (!params?.user) {
        return (
          <div>{componentType}: erreur de paramètre : user est obligatoire</div>
        );
      }

      if (!componentProps.name) {
        return (
          <div>{componentType}: erreur de paramètre : name est obligatoire</div>
        );
      }

      return (
        <DataPaiementsMode
          {...componentProps}
          name={componentProps.name}
          user={params.user}
        />
      );
    }

    case 'parent/info': {
      componentProps = props as Partial<DataParentInfoProps>;

      if (!params?.espaceId) {
        return (
          <div>
            {componentType}: erreur de paramètre : espaceId est obligatoire
          </div>
        );
      }

      if (!params.parentCollectionName) {
        return (
          <div>
            {componentType}: erreur de paramètre : parentCollectionName est
            obligatoire
          </div>
        );
      }

      if (!params.parentId) {
        return (
          <div>
            {componentType}: erreur de paramètre : parentId est obligatoire
          </div>
        );
      }

      return (
        <DataParentInfo
          {...componentProps}
          espaceId={params.espaceId}
          parentCollectionName={params.parentCollectionName}
          parentId={params.parentId}
        />
      );
    }

    case 'parent/product': {
      componentProps = props as Partial<DataParentProductProps>;

      if (!componentProps.formName) {
        return (
          <div>
            {componentType}: erreur de paramètre : formName est obligatoire
          </div>
        );
      }

      if (!params?.espaceId) {
        return (
          <div>
            {componentType}: erreur de paramètre : espaceId est obligatoire
          </div>
        );
      }

      if (
        !params[componentProps.formName] ||
        !params[componentProps.formName].parent
      ) {
        return (
          <div>
            {componentType}: erreur de paramètre : parent est obligatoire
          </div>
        );
      }

      return (
        <DataParentProduct
          {...componentProps}
          espaceId={params.espaceId}
          formName={componentProps.formName}
          parentId={params[componentProps.formName].parent}
        />
      );
    }

    case 'price': {
      componentProps = props as Partial<DataPriceProps>;

      if (!componentProps.name) {
        return (
          <div>{componentType}: erreur de paramètre : name est obligatoire</div>
        );
      }

      if (!params?.espaceId) {
        return (
          <div>
            {componentType}: erreur de paramètre : espaceId est obligatoire
          </div>
        );
      }

      return (
        <DataPrice
          espaceId={params.espaceId}
          {...componentProps}
          name={componentProps.name}
        />
      );
    }

    case 'prices': {
      componentProps = props as Partial<DataPricesProps>;

      if (!componentProps.formName) {
        return (
          <div>
            {componentType}: erreur de paramètre : formName est obligatoire
          </div>
        );
      }

      if (!params?.espaceId) {
        return (
          <div>
            {componentType}: erreur de paramètre : espaceId est obligatoire
          </div>
        );
      }

      if (!params?.parentCollectionName && !params?.collectionName) {
        return (
          <div>
            {componentType}: erreur de paramètre : parentCollectionName ou
            collectionName est obligatoire
          </div>
        );
      }

      if (!params?.parentId && !params?.docId) {
        return (
          <div>
            {componentType}: erreur de paramètre : parentId ou docId est
            obligatoire
          </div>
        );
      }

      return (
        <DataPrices
          {...componentProps}
          espaceId={params.espaceId}
          formName={componentProps.formName}
          parentCollectionName={
            params.parentCollectionName || params.collectionName
          }
          parentId={params.parentId || params.docId}
        />
      );
    }

    case 'prices/tiers': {
      componentProps = props as Partial<PricesTiersProps>;

      if (!params?.espaceId) {
        return (
          <div>
            {componentType}: erreur de paramètre : espaceId est obligatoire
          </div>
        );
      }

      if (!componentProps.name) {
        return (
          <div>{componentType}: erreur de paramètre : name est obligatoire</div>
        );
      }

      return (
        <PricesTiers
          {...componentProps}
          espaceId={params.espaceId}
          name={componentProps.name}
        />
      );
    }

    case 'produit/parent': {
      componentProps = props as Partial<DataProduitParentProps>;

      if (!componentProps.formName) {
        return (
          <div>
            {componentType}: erreur de paramètre : formName est obligatoire
          </div>
        );
      }

      if (!params?.espaceId) {
        return (
          <div>
            {componentType}: erreur de paramètre : espaceId est obligatoire
          </div>
        );
      }

      if (!params?.parentId) {
        return (
          <div>
            {componentType}: erreur de paramètre : parentId est obligatoire
          </div>
        );
      }

      return (
        <DataProduitParent
          {...componentProps}
          espaceId={params.espaceId}
          formName={componentProps.formName}
          parentId={params.parentId}
        />
      );
    }

    case 'product': {
      componentProps = props as Partial<DataProductProps>;

      if (!componentProps.name) {
        return (
          <div>{componentType}: erreur de paramètre : name est obligatoire</div>
        );
      }

      if (!params?.espaceId) {
        return (
          <div>
            {componentType}: erreur de paramètre : espaceId est obligatoire
          </div>
        );
      }

      return (
        <DataProduct
          espaceId={params.espaceId}
          {...componentProps}
          name={componentProps.name}
        />
      );
    }

    case 'product/input': {
      componentProps = props as Partial<DataProductInputProps>;

      if (!componentProps.name) {
        return (
          <div>{componentType}: erreur de paramètre : name est obligatoire</div>
        );
      }

      if (!params?.espaceId) {
        return (
          <div>
            {componentType}: erreur de paramètre : espaceId est obligatoire
          </div>
        );
      }

      return (
        <DataProductInput
          espaceId={params.espaceId}
          {...componentProps}
          name={componentProps.name}
        />
      );
    }

    case 'product/program': {
      componentProps = props as Partial<DataProduitProgramProps>;

      if (!params?.espaceId) {
        return (
          <div>
            {componentType}: erreur de paramètre : espaceId est obligatoire
          </div>
        );
      }

      return (
        <DataProduitProgram {...componentProps} espaceId={params.espaceId} />
      );
    }

    case 'product/options': {
      componentProps = props as Partial<DataProductOptionsProps>;

      if (!componentProps.formName) {
        return (
          <div>
            {componentType}: erreur de paramètre : formName est obligatoire
          </div>
        );
      }

      if (!params?.espaceId) {
        return (
          <div>
            {componentType}: erreur de paramètre : espaceId est obligatoire
          </div>
        );
      }
      if (!params.docId) {
        return (
          <div>
            {componentType}: erreur de paramètre : docId est obligatoire
          </div>
        );
      }

      return (
        <DataProductOptions
          {...componentProps}
          docId={params.docId}
          espaceId={params.espaceId}
          formName={componentProps.formName}
        />
      );
    }

    case 'product/slots': {
      componentProps = props as Partial<DataProductSlotsProps>;

      if (!params?.espaceId) {
        return (
          <div>
            {componentType}: erreur de paramètre : espaceId est obligatoire
          </div>
        );
      }

      if (!params.docId) {
        return (
          <div>
            {componentType}: erreur de paramètre : docId est obligatoire
          </div>
        );
      }

      return (
        <DataProductSlots
          {...componentProps}
          docId={params.docId}
          espaceId={params.espaceId}
        />
      );
    }

    case 'product/variants': {
      componentProps = props as Partial<DataProductVariantsProps>;

      if (!componentProps.formName) {
        return (
          <div>
            {componentType}: erreur de paramètre : formName est obligatoire
          </div>
        );
      }

      if (!params?.espaceId) {
        return (
          <div>
            {componentType}: erreur de paramètre : espaceId est obligatoire
          </div>
        );
      }

      if (!params.docId) {
        return (
          <div>
            {componentType}: erreur de paramètre : docId est obligatoire
          </div>
        );
      }

      return (
        <DataProductVariants
          {...componentProps}
          docId={params.docId}
          espaceId={params.espaceId}
          formName={componentProps.formName}
        />
      );
    }

    case 'produits/dimensions': {
      componentProps = props as Partial<DataProduitsDimensionsProps>;
      if (!params?.espaceId) {
        return (
          <div>
            {componentType}: erreur de paramètre : espaceId est obligatoire
          </div>
        );
      }

      if (!componentProps.formName) {
        return (
          <div>
            {componentType}: erreur de paramètre : formName est obligatoire
          </div>
        );
      }

      if (!componentProps.name) {
        return (
          <div>{componentType}: erreur de paramètre : name est obligatoire</div>
        );
      }

      return (
        <DataProduitsDimensions
          {...componentProps}
          espaceId={params.espaceId}
          formName={componentProps.formName}
          name={componentProps.name}
        />
      );
    }

    case 'produits/inventaire': {
      componentProps = props as Partial<DataProduitsInventaireProps>;

      if (!params?.espaceId) {
        return (
          <div>
            {componentType}: erreur de paramètre : params.espaceId est
            obligatoire
          </div>
        );
      }

      if (!params.collectionName) {
        return (
          <div>
            {componentType}: erreur de paramètre : params.collectionName est
            obligatoire
          </div>
        );
      }

      return (
        <DataProduitsInventaire
          {...componentProps}
          docCollectionName={params.collectionName}
          docId={params.docId}
          espaceId={params.espaceId}
        />
      );
    }

    case 'professionals/kyc': {
      componentProps = props as Partial<DataProfessionalsKYCProps>;

      if (!componentProps.formName) {
        return (
          <div>
            {componentType}: erreur de paramètre : formName est obligatoire
          </div>
        );
      }
      if (!params || !params.docId) {
        return (
          <div>
            {componentType}: erreur de paramètre : params.docId est obligatoire
          </div>
        );
      }

      if (!params.espaceId) {
        return (
          <div>
            {componentType}: erreur de paramètre : params.espaceId est
            obligatoire
          </div>
        );
      }

      return (
        <DataProfessionalsKYC
          {...props}
          docId={params.docId}
          espaceId={params.espaceId}
          formName={componentProps.formName}
        />
      );
    }

    case 'professionals/multiselect': {
      componentProps = props as Partial<DataProfessionalsMultiSelectProps>;

      if (!params?.espaceId) {
        return (
          <div>
            {componentType} : erreur de paramètre : espaceId est obligatoire
          </div>
        );
      }

      if (!componentProps.name) {
        return (
          <div>
            {componentType} : erreur de paramètre : name est obligatoire
          </div>
        );
      }

      return (
        <DataProfessionalsMultiSelect
          {...componentProps}
          espaceId={params.espaceId}
          name={componentProps.name}
        />
      );
    }

    case 'google/results': {
      componentProps = props as Partial<DataGoogleResultsProps>;

      if (!componentProps.formName) {
        return (
          <div>
            {componentType}: erreur de paramètre : formName est obligatoire
          </div>
        );
      }

      if (!componentProps.field) {
        return (
          <div>
            {componentType}: erreur de paramètre : field est obligatoire
          </div>
        );
      }

      return (
        <DataGoogleResults
          {...componentProps}
          field={componentProps.field}
          formName={componentProps.formName}
        />
      );
    }

    case 'google/search-console': {
      componentProps = props as Partial<DataGoogleSearchConsoleProps>;

      if (!componentProps.field) {
        return (
          <div>
            {componentType}: erreur de paramètre : field est obligatoire
          </div>
        );
      }

      if (!componentProps.formName) {
        return (
          <div>
            {componentType}: erreur de paramètre : formName est obligatoire
          </div>
        );
      }

      return (
        <DataGoogleSearchConsole
          {...componentProps}
          field={componentProps.field}
          formName={componentProps.formName}
        />
      );
    }

    case 'hyperlinks': {
      componentProps = props as Partial<DataHyperLinksProps>;

      if (!componentProps.name) {
        return (
          <div>
            {componentType} : erreur de paramètre : name est obligatoire
          </div>
        );
      }
      if (!componentProps.formName) {
        return (
          <div>
            {componentType} : erreur de paramètre : formName est obligatoire
          </div>
        );
      }

      return (
        <DataHyperlinks
          {...componentProps}
          formName={componentProps.formName}
          name={componentProps.name}
        />
      );
    }

    case 'schemas/datas-label': {
      componentProps = props as Partial<DataSchemasDatasLabelProps>;
      if (!componentProps.name) {
        return (
          <div>
            {componentType} : erreur de paramètre : name est obligatoire
          </div>
        );
      }
      if (!componentProps.formName) {
        return (
          <div>
            {componentType} : erreur de paramètre : formName est obligatoire
          </div>
        );
      }

      return (
        <DataSchemaDatasLabel
          {...componentProps}
          formName={componentProps.formName}
          name={componentProps.name}
        />
      );
    }

    case 'schemas/datas': {
      componentProps = props as Partial<SchemasDatasProps>;

      if (!componentProps.name) {
        return (
          <div>
            {componentType} : erreur de paramètre : name est obligatoire
          </div>
        );
      }
      if (!componentProps.formName) {
        return (
          <div>
            {componentType} : erreur de paramètre : formName est obligatoire
          </div>
        );
      }

      return (
        <SchemasDatas
          {...componentProps}
          formName={componentProps.formName}
          name={componentProps.name}
        />
      );
    }

    case 'interactions': {
      componentProps = props as Partial<DataInteractionsProps>;

      if (!componentProps.name) {
        return (
          <div>
            {componentType} : erreur de paramètre : name est obligatoire
          </div>
        );
      }
      if (!componentProps.formName) {
        return (
          <div>
            {componentType} : erreur de paramètre : formName est obligatoire
          </div>
        );
      }

      return (
        <DataInteractions
          {...componentProps}
          formName={componentProps.formName}
          name={componentProps.name}
        />
      );
    }

    case 'schemas/fields': {
      componentProps = props as Partial<DataSchemasFieldsProps>;

      if (!componentProps.name) {
        return (
          <div>
            {componentType} : erreur de paramètre : name est obligatoire
          </div>
        );
      }
      if (!componentProps.formName) {
        return (
          <div>
            {componentType} : erreur de paramètre : formName est obligatoire
          </div>
        );
      }

      return (
        <DataSchemasFields
          {...componentProps}
          formName={componentProps.formName}
          name={componentProps.name}
        />
      );
    }

    case 'schemas/fields-attributes': {
      componentProps = props as Partial<DataSchemasFieldsAttributesProps>;

      if (!componentProps.name) {
        return (
          <div>
            {componentType} : erreur de paramètre : name est obligatoire
          </div>
        );
      }
      if (!componentProps.formName) {
        return (
          <div>
            {componentType} : erreur de paramètre : formName est obligatoire
          </div>
        );
      }

      if (!params?.espaceId) {
        return (
          <div>
            {componentType} : erreur de paramètre : espaceId est obligatoire
          </div>
        );
      }

      return (
        <DataSchemasFieldsAttributes
          {...componentProps}
          espaceId={params.espaceId}
          formName={componentProps.formName}
          name={componentProps.name}
        />
      );
    }

    case 'schemas/fields-select': {
      componentProps = props as Partial<DataSchemasFieldsSelectProps>;

      if (!componentProps.name) {
        return (
          <div>
            {componentType} : erreur de paramètre : name est obligatoire
          </div>
        );
      }
      if (!componentProps.formName) {
        return (
          <div>
            {componentType} : erreur de paramètre : formName est obligatoire
          </div>
        );
      }

      return (
        <DataSchemasFieldsSelect
          {...componentProps}
          formName={componentProps.formName}
          name={componentProps.name}
        />
      );
    }

    case 'subscription/products': {
      componentProps = props as Partial<DataSubscriptionProductsProps>;

      if (!params?.espaceId) {
        return (
          <div>
            {componentType}: erreur de paramètre : espaceId est obligatoire
          </div>
        );
      }

      if (!componentProps.formName) {
        return (
          <div>
            {componentType} : erreur de paramètre : formName est obligatoire
          </div>
        );
      }
      if (!componentProps.name) {
        return (
          <div>
            {componentType} : erreur de paramètre : name est obligatoire
          </div>
        );
      }

      return (
        <DataSubscriptionProducts
          {...componentProps}
          espaceId={params.espaceId}
          formName={componentProps.formName}
          name={componentProps.name}
        />
      );
    }

    case 'schemas/list': {
      componentProps = props as Partial<DataSchemasListProps>;
      if (!componentProps.name) {
        return (
          <div>
            {componentType} : erreur de paramètre : name est obligatoire
          </div>
        );
      }
      if (!componentProps.formName) {
        return (
          <div>
            {componentType} : erreur de paramètre : formName est obligatoire
          </div>
        );
      }

      return (
        <DataSchemasList
          {...componentProps}
          formName={componentProps.formName}
          name={componentProps.name}
        />
      );
    }

    case 'schemas/select': {
      componentProps = props as Partial<DataSchemasSelectProps>;
      if (!componentProps.name) {
        return (
          <div>
            {componentType} : erreur de paramètre : name est obligatoire
          </div>
        );
      }

      return (
        <DataSchemasSelect {...componentProps} name={componentProps.name} />
      );
    }

    case 'tarifications/select': {
      componentProps = props as TarificationsSelectProps;

      if (!params?.espaceId) {
        return (
          <div>
            {componentType}: erreur de paramètre : espaceId est obligatoire
          </div>
        );
      }

      if (!componentProps.formName) {
        return (
          <div>
            {componentType}: erreur de paramètre : formName est obligatoire
          </div>
        );
      }
      if (!componentProps.name) {
        return (
          <div>{componentType}: erreur de paramètre : name est obligatoire</div>
        );
      }

      return (
        <TarificationsSelect
          {...componentProps}
          espaceId={params.espaceId}
          name={componentProps.name}
        />
      );
    }

    case 'thoughts/crud': {
      componentProps = props as Partial<DataThoughtsCrudProps>;

      if (!componentProps.formName) {
        return (
          <div>
            {componentType}: erreur de paramètre : formName est obligatoire
          </div>
        );
      }

      if (!componentProps.name) {
        return (
          <div>{componentType}: erreur de paramètre : name est obligatoire</div>
        );
      }

      if (!params?.collectionName) {
        return (
          <div>
            {componentType}: erreur de paramètre : collectionName est
            obligatoire
          </div>
        );
      }

      if (!params.docId) {
        return (
          <div>
            {componentType}: erreur de paramètre : docId est obligatoire
          </div>
        );
      }

      return (
        <DataThoughtsCrud
          {...componentProps}
          espaceId={params.espaceId}
          formName={componentProps.formName}
          name={componentProps.name}
          parentCollectionName={params.collectionName}
          parentId={params.docId}
        />
      );
    }

    case 'users/select': {
      componentProps = props as Partial<DataUsersSelectProps>;

      if (!componentProps.formName) {
        return (
          <div>
            {componentType}: erreur de paramètre : formName est obligatoire
          </div>
        );
      }

      if (!componentProps.name) {
        return (
          <div>{componentType}: erreur de paramètre : name est obligatoire</div>
        );
      }

      if (!params?.espaceId) {
        return (
          <div>
            {componentType}: erreur de paramètre : espaceId est obligatoire
          </div>
        );
      }

      return (
        <DataUsersSelect
          {...componentProps}
          espaceId={params.espaceId}
          formName={componentProps.formName}
          name={componentProps.name}
        />
      );
    }

    case 'utility/counter': {
      componentProps = props as Partial<DataUtilityCounterProps>;

      if (!componentProps.field) {
        return (
          <div>
            {componentType}: erreur de paramètre : field est obligatoire
          </div>
        );
      }

      if (!componentProps.formName) {
        return (
          <div>
            {componentType}: erreur de paramètre : formName est obligatoire
          </div>
        );
      }

      return (
        <DataUtilityCounter
          {...componentProps}
          field={componentProps.field}
          formName={componentProps.formName}
        />
      );
    }

    case 'utility/qrcode': {
      componentProps = props as Partial<DataUtilityQRCodeProps>;

      if (!componentProps.field) {
        return (
          <div>
            {componentType}: erreur de paramètre : field est obligatoire
          </div>
        );
      }

      if (!componentProps.formName) {
        return (
          <div>
            {componentType}: erreur de paramètre : formName est obligatoire
          </div>
        );
      }

      return (
        <DataUtilityQRCode
          formName={componentProps.formName}
          {...componentProps}
          field={componentProps.field}
        />
      );
    }

    case 'variants': {
      componentProps = props as Partial<DataVariantsProps>;

      if (!componentProps.name) {
        return (
          <div>{componentType}: erreur de paramètre : name est obligatoire</div>
        );
      }

      if (!componentProps.formName) {
        return (
          <div>
            {componentType}: erreur de paramètre : formName est obligatoire
          </div>
        );
      }

      return (
        <DataVariants
          formName={componentProps.formName}
          {...componentProps}
          name={componentProps.name}
        />
      );
    }

    default:
  }

  return null;
};

export default Data;
