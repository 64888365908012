import 'firebase/auth';

import { DocumentType, ScenarioType } from '@innedit/innedit-type';

import Model, { ModelProps } from '../Model';

class Scenario extends Model<ScenarioType> {
  constructor(props?: Omit<ModelProps<ScenarioType>, 'collectionName'>) {
    super({
      ...props,
      collectionName: 'scenarios',
      orderDirection: props?.orderDirection || 'asc',
      orderField: props?.orderField || 'label',
      queryBy: 'label',
      tabs: [
        {
          itemMode: 'grid',
          label: 'Tous les scénarios',
          orderDirection: 'desc',
          orderField: 'createdAt',
          pathname: `/admin/scenarios`,
          wheres: {
            archived: false,
          },
        },
        {
          label: 'En vedette',
          orderDirection: 'desc',
          orderField: 'createdAt',
          pathname: `/admin/scenarios/featured`,
          wheres: {
            archived: false,
            isFeatured: true,
          },
        },
        {
          label: 'Archivées',
          orderDirection: 'desc',
          orderField: 'createdAt',
          pathname: `/admin/scenarios/archived`,
          wheres: {
            archived: true,
          },
        },
      ],
    });
  }

  public async create(
    data: Partial<ScenarioType>,
    id?: string,
  ): Promise<DocumentType<ScenarioType>> {
    return super.create({
      ...data,
      timeConstraint: {
        ...data.timeConstraint,
        isActive: data.timeConstraint?.isActive ?? false,
      },
    });
  }
}

export default Scenario;
